<template>
  <div class="index">
    <div class="bg-color"></div>
    <div class="index-tit">莲花县疫情防控人口采集管理</div>
    <div class="info flex">
      <img src="@/assets/head.png" v-if="avatar == ''" />
      <img :src="avatar" v-else />
      <div>{{name}},您好！</div>
    </div>
    <img src="@/assets/top-img.png" class="top-img" />
    <div class="nav-item-div flex" @click="toUrl('/form')">
      <img src="@/assets/icon-a.png" />
      <span>居民健康码</span>
      <div class="nav-tips">立即申领</div>
    </div>
    <div class="nav-item-div flex nav-item-div2" @click="toUrl('/list')">
      <img src="@/assets/icon-b.png" />
      <span>申领记录</span>
      <div class="nav-tips">点击查看</div>
    </div>
    <!-- <div class="flex between nav-item2">
      <div class="flex nav-item-div3" @click="toUrl('/login')">
        <img src="@/assets/icon-c.png" />
        <div class="nav-tips">企业信息</div>
      </div>
      <div class="flex nav-item-div3" @click="toUrl('/login')">
        <img src="@/assets/icon-d.png" />
        <div class="nav-tips">驻地单位<br>信息</div>
      </div>
      <div class="flex nav-item-div3" @click="toUrl('/login')">
        <img src="@/assets/icon-e.png" />
        <div class="nav-tips">个体户信息</div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { detail, wechatLogin } from "@/api/apiData";
import GetQueryString from "@/until/getParams";

export default {
  data() {
    return {
      name: "",
      avatar: "",
    };
  },
  mounted() {
    let code = GetQueryString("code");
    var logins = localStorage.getItem("logins");
    if (code) {
      if (logins == 2) {
        this.getInfo();
      } else {
        this.login();
      }
    } else {
      this.getInfo();
    }
    // this.getInfo()
  },
  methods: {
    // 登陆
    login() {
      wechatLogin({
        code: GetQueryString("code"),
      }).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("logins", 2);
          this.getInfo();
        } else {
          this.getInfo();
        }
      });
    },
    // 用户详情
    getInfo() {
      detail().then((res) => {
        this.name = res.data.nickname;
        this.avatar = res.data.avatar;
      });
    },
    toUrl(u) {
      this.$router.push(u);
    },
  },
};
</script>
<style lang="less" scoped>
.bg-color {
  background: #0038cf;
  width: 100%;
  height: 130px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.index-tit {
  color: white;
  text-align: center;
  margin: 40px 0 20px;
  font-size: 16px;
  font-weight: bold;
}
.info {
  color: #4b4b4b;
  font-size: 15px;
  font-weight: bold;
  background: white;
  width: 90%;
  margin: 10px auto 10px;
  border-radius: 10px;
  padding: 10px 0;
  box-shadow: 0px 0px 3px 0px rgb(215, 215, 218);
  img {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    margin-right: 20px;
    margin-left: 20px;
  }
}
.top-img {
  width: 92%;
  margin: 0 auto;
  display: block;
}
.nav-item-div {
  background: #4a88ed;
  width: 90%;
  margin: 10px auto 10px;
  color: white;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  border-radius: 10px;
  align-items: flex-start;
  padding: 20px 0;
  img {
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
  .nav-tips {
    color: #fdcf3b;
    font-size: 14px;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  span {
    width: 80%;
    line-height: 1;
    margin-left: 20px;
  }
}
.nav-item-div2 {
  background: #61c4a9;
}
.nav-item2 {
  width: 90%;
  margin: 0 auto 10px;
  flex-wrap: wrap;
}
.nav-item-div3 {
  width: 49%;
  align-items: center;
  background: #2692fa;
  border-radius: 10px;
  color: white;
  font-size: 17px;
  font-weight: bold;
  padding: 20px 0;
  height: 45px;
  margin-bottom: 10px;
  justify-content: center;
  img {
    width: 40px;
    height: 40px;
  }
  .nav-tips {
    margin-left: 10px;
  }
}
</style>